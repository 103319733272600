<template>
    <div style="display: inline;margin-left: 10px;">

        <el-button type="primary" @click="Fun">导出文档</el-button>

        <el-dialog :close-on-click-modal="false" title="导出" :visible.sync="show" width=" calc(21cm / 1.5 + 40px) ">


            <div id="btnBox1" v-if="this.infoDict.type&&this.infoDict.type==2">

                <div class="wordExport">


                    <h6 class="title">医疗废物数据阶段报告</h6>
                    <h6 class="subtitle">（监管单位）</h6>
                    <h6 class="number">报告编号：{{info.number}}</h6>
                    <h6 class="">单位名称：{{info.dep_name}}</h6>
                    <h6 class="">报告时间： {{info.time?(info.time[0]+"至"+info.time[1]):"全部"}}</h6>
                    <h6 class="">此段时间内，所监管机构医疗废物监管数据如下：</h6>
                    <h6 class="">一、按医废类别统计 </h6>
                    <table>
                        <tr>
                            <td></td>
                            <td>医废类别</td>
                            <td>包数（包）</td>
                            <td>重量（kg）</td>
                        </tr>
                        <tr v-for="(item,index) in info.item" :key="index">
                            <td>{{index+1}}</td>
                            <td>{{item.item_name}}</td>
                            <td>{{item.total}}</td>
                            <td>{{item.weight}}</td>
                        </tr>
                        <tr>
                            <td colspan="2">合计</td>
                            <td>{{

                        info.item.map(x=>{
                            return x.total
                        }).reduce(function(prev,cur){
                        return prev + cur
                        },0)
                            
                    }}</td>
                            <td>{{
                        Number(
                        info.item.map(x=>{
                            return x.weight
                        }).reduce(function(prev,cur){
                        return prev + cur
                        },0)
                        ).toFixed(2)                            
                    }}</td>
                        </tr>
                    </table>

                    <h6 class="">二、按机构统计</h6>
                    <h6 class="">1、独立机构：</h6>
                    <table>
                        <tr>
                            <td style="width: 40px;"></td>
                            <td>医院名称</td>
                            <td>医废类别</td>
                            <td>包数（包）</td>
                            <td>重量（kg）</td>
                        </tr>



                        <tbody v-for="(item,index) in info.dep" :key="index">
                            <tr v-for="(ite,ind) in item.item" :key="ind">
                                <td :rowspan="item.item?item.item.length:0" v-if="ind==0">{{index+1}}</td>
                                <td :rowspan="item.item?item.item.length:0" v-if="ind==0">{{item.dep_name}}</td>
                                <td>{{ite.item_name}}</td>
                                <td>{{ite.total}}</td>
                                <td>{{ite.weight}}</td>
                            </tr>
                        </tbody>






                        <tr>
                            <td colspan="3">合计</td>
                            <td>{{
                        info.depAll.map(x=>{
                            return x.total
                        }).reduce(function(prev,cur){
                        return prev + cur
                        },0)
                    }}</td>
                            <td>{{
                        Number(
                        info.depAll.map(x=>{
                            return x.weight
                        }).reduce(function(prev,cur){
                        return prev + cur
                        },0)
                        ).toFixed(2)                            
                    }}</td>

                        </tr>

                    </table>

                    <h6 class="">2、中转机构：</h6>

                    <table>
                        <tr>
                            <td style="width: 40px;"></td>
                            <td>机构名称</td>
                            <td>医废类别</td>
                            <td>包数（包）</td>
                            <td>重量（kg）</td>
                        </tr>

                        <tbody v-for="(item,index) in info.dep_child" :key="index">
                            <tr v-for="(ite,ind) in item.item" :key="ind">
                                <td :rowspan="item.item?item.item.length:0" v-if="ind==0">{{index+1}}</td>
                                <td :rowspan="item.item?item.item.length:0" v-if="ind==0">{{item.dep_name}}</td>
                                <td>{{ite.item_name}}</td>
                                <td>{{ite.total}}</td>
                                <td>{{ite.weight}}</td>
                            </tr>
                        </tbody>

                        <tr>
                            <td colspan="3">合计</td>
                            <td>{{
                        info.dep_childAll.map(x=>{
                            return x.total
                        }).reduce(function(prev,cur){
                        return prev + cur
                        },0)
                    }}</td>
                            <td>{{
                        Number(
                        info.dep_childAll.map(x=>{
                            return x.weight
                        }).reduce(function(prev,cur){
                        return prev + cur
                        },0)
                        ).toFixed(2)                            
                    }}</td>

                        </tr>

                    </table>


                    <h6 class="">（完）</h6>



                </div>

            </div>




            <div id="btnBox" v-else>

                <div class="wordExport">


                    <h6 class="title">医疗废物数据阶段报告</h6>
                    <h6 class="subtitle">（独立账号）</h6>
                    <h6 class="number">报告编号：{{info.number}}</h6>
                    <h6 class="">机构名称：{{info.dep_name}}</h6>
                    <h6 class="">报告时间： {{info.time?(info.time[0]+"至"+info.time[1]):"全部"}}</h6>
                    <h6 class="">此段时间内，所关联机构/科室医疗废物处理数据如下：</h6>
                    <h6 class="">一、按医废类别统计 </h6>
                    <table>
                        <tr>
                            <td></td>
                            <td>医废类别</td>
                            <td>包数（包）</td>
                            <td>重量（kg）</td>
                        </tr>
                        <tr v-for="(item,index) in info.item" :key="index">
                            <td>{{index+1}}</td>
                            <td>{{item.item_name}}</td>
                            <td>{{item.total}}</td>
                            <td>{{item.weight}}</td>
                        </tr>
                    </table>

                    <h6 class="">二、按科室类别统计</h6>
                    <h6 class="">1、自有科室：</h6>
                    <table>
                        <tr>
                            <td></td>
                            <td>科室名称</td>
                            <td>包数（包）</td>
                            <td>重量（kg）</td>
                        </tr>
                        <tr v-for="(item,index) in info.this_level" :key="index">
                            <td>{{index+1}}</td>
                            <td>{{item.level_name}}</td>
                            <td>{{item.total}}</td>
                            <td>{{item.weight}}</td>
                        </tr>
                        <tr>
                            <td colspan="2">合计</td>
                            <td>{{
                        info.this_level.map(x=>{
                            return x.total
                        }).reduce(function(prev,cur){
                        return prev + cur
                        },0)
                    }}</td>
                            <td>{{
                            Number(                           
                        info.this_level.map(x=>{
                            return x.weight
                        }).reduce(function(prev,cur){
                        return prev + cur
                        },0)
                        ).toFixed(2)       
                    }}</td>
                        </tr>
                    </table>

                    <h6 class="">2、中转机构：</h6>
                    <table>
                        <tr>
                            <td></td>
                            <td>机构名称</td>
                            <td>包数（包）</td>
                            <td>重量（kg）</td>
                        </tr>
                        <tr v-for="(item,index) in info.this_dep" :key="index">
                            <td>{{index+1}}</td>
                            <td>{{item.dep_name}}</td>
                            <td>{{item.total}}</td>
                            <td>{{item.weight}}</td>
                        </tr>
                        </tr>
                        <tr>
                            <td colspan="2">合计</td>
                            <td>{{
                        info.this_dep.map(x=>{
                            return x.total
                        }).reduce(function(prev,cur){
                        return prev + cur
                        },0)
                            
                    }}</td>
                            <td>{{
                            Number(                           
                        info.this_dep.map(x=>{
                            return x.weight
                        }).reduce(function(prev,cur){
                        return prev + cur
                        },0)
                        ).toFixed(2)       
                    }}</td>
                        </tr>
                    </table>

                    <h6 class="">（完）</h6>


                </div>

            </div>



            <span slot="footer">
                <div class="buttons">

                    <el-button type="primary" @click="ExportDocument2">导出文档</el-button>

                </div>
            </span>


        </el-dialog>

    </div>
</template>


<script>
    import FileSaver from 'file-saver'
    import htmlDocx from "html-docx-js/dist/html-docx"



    export default {



        props: ["wasteInfo"],

        data() {
            return {
                show: false,

                info: {
                    dep_name: "",
                    time: "",
                    item: [],
                    this_level: [],
                    this_dep: [],
                    dep: [],
                    dep_child: []
                },

                infoDict: {},

            }
        },
        created() {


        },

        mounted() {

            // setTimeout(x => {
            //     this.getData()
            // }, 500)

            // setTimeout(x=>{
            //     this.ExportDocument2()
            // },1000)
        },



        methods: {
            Fun() {

                // 仅在数据对比有这功能

                var a = {
                    ...this.wasteInfo
                }






                // if (this.ExportTime(a) == false) {
                //     this.$message.error("间隔时间不能大于3个月")
                //     return
                // }
                // console.log("处理   type    导出类型：1封箱、2入库、3出库、4数据对比、5可回收    ");


                var username = JSON.parse(localStorage.getItem('user')).username
                a.account = username

                if (this.wasteInfo.dep_id.length > 0) {
                    a.account = ""

                    a.dep_id = this.wasteInfo.dep_id.slice(-1)[0]



                }




                var position_id = JSON.parse(localStorage.getItem('user')).position_id
                a.type = Number(position_id) == 16 ? 2 : 1

                console.log(a);

                // if (a.type == 1) {
                // }
                // if (a.type == 2) {
                // }
                // if (a.type == 3) {
                // }
                // if (a.type == 4) {
                // }
                // if (a.type == 5) {
                // }
                // if (a.type == 6) {
                // }

                //     collector_time: ""
                //     create_time: ""


                delete a.out_storage_time
                delete a.sealing_time
                delete a.storage_time

                delete a.create_time
                delete a.collector_time


                if (this.UserVersionId == 1) {
                    a.create_time = this.wasteInfo.create_time.toString()
                }
                if (this.UserVersionId == 2) {
                    a.collector_time = this.wasteInfo.collector_time.toString()
                }

                this.getData1(a)

                // this.show = true
            },








            getQueryObject(url) {
                url = url == null ? window.location.href : url;
                var search = url.substring(url.lastIndexOf("?") + 1);
                var obj = {};
                var reg = /([^?&=]+)=([^?&=]*)/g;
                search.replace(reg, function(rs, $1, $2) {
                    var name = decodeURIComponent($1);
                    var val = decodeURIComponent($2);
                    val = String(val);
                    obj[name] = val;
                    return rs;
                });
                return obj;
            },



            // 停用
            getData() {

                // var a = {
                //     account: "test",
                //     is_in_storage: "",
                //     create_time: "2023-06-01,2023-08-01",
                //     storage_time: "",
                //     out_storage_time: "",
                // }

                // file:///C:/Users/Administrator/Desktop/%E6%96%B0%E5%BB%BA%E6%96%87%E4%BB%B6%E5%A4%B9/index.html?account=test&is_in_storage=&create_time=2023-06-01,2023-08-01&storage_time=&out_storage_time=


                var url = window.location.href;

                var dict = url.split("?")[1]

                console.log(dict)

                this.infoDict = this.getQueryObject(url)

                console.log(this.infoDict)



                this.infoDict = {
                    type: 1,
                    account: "test",
                    is_in_storage: "",
                    create_time: "2023-06-01,2023-08-01",
                    storage_time: "",
                    out_storage_time: "",
                }




                this.$http.get('/api/word/index', this.infoDict).then((res) => {
                    console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);

                    }
                    if (res.code == 200) {

                        console.log(res.data)
                        this.info = res.data
                        this.info.number = this.TimeNum()
                        this.DataHandle()

                        // setTimeout(x => {
                        //     this.ExportDocument2()
                        // }, 500)

                    }
                }).catch((err) => {
                    console.log(err)
                });

            },

            getData1(val) {


                // this.infoDict = {
                //     type: 1,
                //     account: "test",
                //     is_in_storage: "",
                //     create_time: "2023-06-01,2023-08-01",
                //     storage_time: "",
                //     out_storage_time: "",
                // }





                this.infoDict = {
                    type: val.type,
                    account: val.account,
                    dep_id: val.dep_id,
                    create_time: val.create_time,
                    is_in_storage: val.is_in_storage,
                    storage_time: val.storage_time,
                    out_storage_time: val.out_storage_time,
                }



                this.$http.get('/api/word/index', this.infoDict).then((res) => {
                    console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);

                    }
                    if (res.code == 200) {

                        // console.log(res.data)
                        this.info = {
                            ...res.data
                        }


                        this.info.time = this.info.time ? this.info.time : ""


                        this.info.number = this.TimeNum()
                        this.DataHandle()

                        // setTimeout(x => {
                        //     this.ExportDocument2()
                        // }, 500)



                        this.show = true

                    }
                }).catch((err) => {
                    console.log(err)
                });

            },


            DataHandle() {

                var a = []
                this.info.dep.map(x => {
                    a = a.concat(x.item)
                })
                this.info.depAll = a

                var b = []
                this.info.dep_child.map(x => {
                    b = b.concat(x.item)
                })
                this.info.dep_childAll = b

            },

            TimeNum() {
                let date = new Date();
                let year = date.getFullYear(); //获取当前年份
                let mon = ("0" + (date.getMonth() + 1)).slice(-2); //获取当前月份
                let da = ("0" + date.getDate()).slice(-2); //获取当前日
                let h = ("0" + date.getHours()).slice(-2); //获取小时
                let m = ("0" + date.getMinutes()).slice(-2); //获取分钟
                let s = ("0" + date.getSeconds()).slice(-2); //获取秒
                // this.date = `${year}-${mon}-${da} `;
                // this.time = `${h}:${m}:${s}`;

                return `${year}${mon}${da}${h}${m}${s}`
            },

            ExportDocument2() {
                if (this.infoDict.type && this.infoDict.type == 2) { //存在 或 等于 2  下载  第2个            
                    this.list('btnBox1')
                } else {
                    this.list('btnBox')
                }
            },



            list(val) {

                // 界面样式 需放到 数据流 里


                // let contentHtml = document.getElementById("btnBox").innerHTML

                let contentHtml = document.getElementById(val).innerHTML


                let content = `<!DOCTYPE html><html>
 <head>
 <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">

<style>

.wordExport {
       /* // border: 1px red solid; */
       box-sizing: border-box;
       width: 21cm;
       height: 29.7cm;
       display: inline-block;
/*       // transform: scale(0.7);
       // padding: 2.5cm; */
       background-color: white;
        overflow: scroll;
   } 
   
   h6{
   
   width: 100%;
   display: inline-block;
   
   /* // border: 1px red solid; */
   box-sizing: border-box;
   
   text-align: left;
   font-size: 16px;
   padding: 0.1cm;
   }
      
       
  .title{
       text-align: center;
       font-size: 18px;
       font-weight: 600;
   }
  .subtitle{
       text-align: center;
       font-size: 18px;
   }
   .number{
       text-align: right;
   }
   
   table{
       
      border-collapse: collapse;  /* 合并边框 */
      width: 100%;  /* 设置表格宽度为100% */
      margin-bottom: 0.5cm;
   }
   
   th,  td {
       border: 1px solid black;  /* 设置单元格边框为1px黑色实线 */
       padding: 10px;  /* 设置单元格内边距为10px */
       text-align: center;  /* 设置单元格中的文字居中显示 */
   }
    th {
        background-color: lightgray;  /* 设置表头背景颜色为浅灰色 */
   }

</style>

 </head>
 <body>
 ${contentHtml}
 </body>
 </html>`;

                let converted = htmlDocx.asBlob(content);

                // console.log(converted);

                FileSaver.saveAs(converted, '医废数据报告.docx');

            },



        }
    }
</script>


<style scoped>
    .wordExport {
        /* // border: 1px red solid; */
        box-sizing: border-box;
        width: calc(21cm / 1.5);
        height: calc(29.7cm / 2);
        display: inline-block;
        /*       // transform: scale(0.7);
       // padding: 2.5cm; */
        background-color: white;
        overflow: scroll;

    }

    h6 {

        width: 100%;
        display: inline-block;

        /* // border: 1px red solid; */
        box-sizing: border-box;

        text-align: left;
        font-size: 16px;
        padding: 0.1cm;

    }


    .title {
        text-align: center;
        font-size: 18px;
        font-weight: 600;
    }

    .subtitle {
        text-align: center;
        font-size: 18px;
    }

    .number {
        text-align: right;
    }

    table {

        border-collapse: collapse;
        /* 合并边框 */
        width: 100%;
        /* 设置表格宽度为100% */
        margin-bottom: 0.5cm;
    }



    th,
    td {
        border: 1px solid black;
        /* 设置单元格边框为1px黑色实线 */
        padding: 10px;
        /* 设置单元格内边距为10px */
        text-align: center;
        /* 设置单元格中的文字居中显示 */
    }

    th {
        background-color: lightgray;
        /* 设置表头背景颜色为浅灰色 */
    }
</style>