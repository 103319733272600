<template>
    <div class="level_index level_indexII">
        <!--      <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="PDA封箱管理" name="001"> -->

        <div class="search">
            <el-form :inline="true">
                <el-form-item prop="title" style="width: calc(100% - 100px)">

                    <el-form-item label="医废编号">
                        <el-input clearable v-model="wasteInfo.number" placeholder="请输入医废编号" class="SelfSelect" />
                    </el-form-item>

                    <el-form-item label="医废类型" v-show="ISSenior">
                        <el-select clearable v-model="wasteInfo.item_id" placeholder="请选择医废类型" class="SelfSelect">
                            <el-option :label="item.name" :value="item.id" v-for="item in ItemTypeList"
                                :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="所属医院" v-show="ISSenior">
                        <el-select clearable v-model="wasteInfo.dep_id" placeholder="请选择所属医院" class="SelfSelect">
                            <el-option :label="item.name" :value="item.id" v-for="item in depList"
                                :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="是否封箱" v-show="ISSenior">
                        <!-- 是否封箱：1已封箱 2未封箱 -->
                        <el-switch v-model="wasteInfo.rkstatus" :active-value="1" active-color="#5BD995" active-text="是"
                            :inactive-value="2" inactive-color="#D9D9D9" inactive-text="否" class="switchSelfBox"
                            style="margin-top: 5px">
                        </el-switch>
                    </el-form-item>

                    <el-form-item label="科室" v-show="ISSenior">
                        <el-select v-model="wasteInfo.level_id" clearable placeholder="请选择科室" class="SelfSelect">
                            <el-option :label="item.name" :value="item.id" v-for="item in levelList"
                                :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>


                    <el-form-item label="封箱时间" v-show="ISSenior">
                        <!-- <el-date-picker v-model="wasteInfo.sealing_time" type="date" format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd" placeholder="选择封箱时间" class="SelfSelect">
                                </el-date-picker> -->

                        <el-date-picker v-model="wasteInfo.sealing_time" type="daterange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd" style="height: 36px; display: flex;align-items: baseline;">
                        </el-date-picker>

                    </el-form-item>


                    <el-button style="margin-left: 10px" type="primary" @click="tosearch">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        查询
                    </el-button>
                </el-form-item>

                <el-form-item style="float: right; margin: 0">
                    <el-button v-show="!ISSenior" type="primary" @click="ISSenior = !ISSenior" style="margin: 0">
                        高级搜索
                    </el-button>
                    <el-button v-show="ISSenior" type="danger" @click="ISSenior = !ISSenior" style="margin: 0">
                        高级搜索
                    </el-button>
                </el-form-item>
            </el-form>
        </div>

        <div class="OutputBox">
            <span><i class="fa fa-line-chart" aria-hidden="true"></i>
                <small>医废汇总：共{{data.total}}包，{{data.weight}}KG</small>
            </span>

            <el-button class="block" @click="DetailExport(wasteInfo)">明细导出</el-button>
            <el-button class="block" @click="SummaryExport(wasteInfo)">汇总导出</el-button>

            <!-- type	导出类型：1封箱、2入库、3出库、4数据对比、5可回收 -->
            <!-- <outWord :wasteInfo="wasteInfo"></outWord> -->

        </div>



        <el-table class="el-tableII FanfuTableBox" :data="data.list" stripe height="100%" border ref="multipleTable"
            @row-click="rowclick">
            <el-table-column show-overflow-tooltip type="selection" min-width="40" align="center" fixed="left" />
            <el-table-column show-overflow-tooltip label="序号" type="index" align="center" fixed="left" />

            <el-table-column show-overflow-tooltip prop="number" label="医废编号" align="left" min-width="150" />
            <el-table-column show-overflow-tooltip prop="dep_name" label="医院" align="left" min-width="150" />
            <el-table-column show-overflow-tooltip prop="level_name" label="科室" align="left" min-width="150" />
            <el-table-column show-overflow-tooltip prop="item_name" label="类型" align="left" min-width="60" />
            <el-table-column show-overflow-tooltip prop="pinyin" label="总重量(Kg)" align="left" min-width="83" />

            <el-table-column show-overflow-tooltip prop="" label="是否封箱" align="left" min-width="90">
                <template slot-scope="scope">
                    <!-- rkstatus 1已封箱 2未封箱 -->
                    <el-button type="primary" round v-if="scope.row.rkstatus == 1">已封箱</el-button>
                    <el-button type="info" round v-if="scope.row.rkstatus == 2">未封箱</el-button>
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="realname" label="收集转移人" align="left" min-width="120" />

            <el-table-column show-overflow-tooltip prop="" label="收集时间" align="left" min-width="150">
                <template slot-scope="scope">
                    <span>{{ scope.row.create_time }}</span>
                </template>
            </el-table-column>


            <el-table-column show-overflow-tooltip prop="Temporary" label="科室交接人员签字" align="center" min-width="120">
                <template slot-scope="scope">
                    <img src="" v-if="scope.row.sign_img" alt="" v-bind:src="GLOBAL.BASE_URL + scope.row.sign_img"
                        class="CoverImage" />
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="Third" label="转运人员收集签字" align="center" min-width="120">
                <template slot-scope="scope">
                    <img src="" v-if="scope.row.signa_img" alt="" v-bind:src="GLOBAL.BASE_URL + scope.row.signa_img"
                        class="CoverImage" />
                </template>
            </el-table-column>


            <el-table-column show-overflow-tooltip label="操作" align="center"
                :min-width=" autoTableWidth(['sys:number:edit','sys:number:drop'],75)">
                <template slot-scope="scope">
                    <div class="operate">

                        <el-button type="primary" @click="edit(scope.row)" class="operationBtn"
                            v-if="getPurviewNew('sys:number:edit')"> 编辑 </el-button>

                        <el-button type="danger" @click="del(scope.row)" class="operationBtn"
                            v-if="getPurviewNew('sys:number:drop')"> 删除 </el-button>

                    </div>
                </template>
            </el-table-column>
        </el-table>


        <el-pagination layout="total, prev, pager, next, jumper" background :current-page.sync="data.page"
            :page-size="data.size" :total="data.total" @current-change="handleCurrentChange" />

        <!--                <br />
                <br />

            </el-tab-pane>

            <el-tab-pane label="小程序封箱管理" name="002" />
        </el-tabs> -->



        <Search ref="searchRef" v-model="search" @refresh="getList(data.size)" />
    </div>
</template>

<script>
    import Search from './components/WasteSealingSearch'
    import resizeDetector from 'element-resize-detector'

    import outWord from '../../layout/components/outWord.vue'

    import {
        mapState,
        mapMutations
    } from 'vuex'

    export default {
        components: {
            Search,
            outWord
        },
        data() {
            return {
                search: {
                    title: '',
                    name2: '',
                    name3: '',
                    name4: '',
                    name5: '',
                    name6: ''
                },
                loading: false,
                select: [],
                data: {
                    list: [],
                    page: 1,
                    size: 15,
                    total: 0,
                    weight: 0,
                },

                ISSenior: false,

                wasteInfo: {
                    number: '', //医废编号
                    item_id: '', //医废类型标识id
                    collector_id: '', //小程序端录入人标识id
                    collector_time: '', //小程序端录入时间
                    create_user: '', //小程序端转运人标识id、 PDA端收集人 / 准运人标识id
                    create_time: '', //小程序端转运时间、 PDA端收集人录入 / 准运时间
                    dep_id: '', //组织机构标识id
                    rkstatus: '', //是否封箱： 1 已封箱 2 未封箱
                    sealing_time: '', //PDA封箱时间
                    level_id: '', //科室标识ID
                    is_in_storage: '', //是否入库0未入库1已入库2已出库入库时间
                    storage_time: '', //入库时间
                    out_storage_time: '' //出库时间
                },

                ItemTypeList: [], //医废类型

                depList: [], //机构

                levelList: [], //机构

                activeName: '001'
            }
        },

        computed: {
            ...mapState('m_item', [
                'LevelList',
                'PositionList',
                'DepList',
                'HealthList',
                'Province',
                'City',
                'District',
                'RoleList',
                'Depcate',
                'ItemType'
            ])
        },

        watch: {
            ISSenior(Value) {
                this.wasteInfo = {
                    number: '', //医废编号
                    item_id: '', //医废类型标识id
                    collector_id: '', //小程序端录入人标识id
                    collector_time: '', //小程序端录入时间
                    create_user: '', //小程序端转运人标识id、 PDA端收集人 / 准运人标识id
                    create_time: '', //小程序端转运时间、 PDA端收集人录入 / 准运时间
                    dep_id: '', //组织机构标识id
                    rkstatus: '', //是否封箱： 1 已封箱 2 未封箱
                    sealing_time: '', //PDA封箱时间
                    level_id: '', //科室标识ID
                    is_in_storage: '', //是否入库0未入库1已入库2已出库入库时间
                    storage_time: '', //入库时间
                    out_storage_time: '' //出库时间
                }
            },


            "wasteInfo": {
                deep: true, //深度监听设置为 true
                handler: function(newVal, oldVal) {
                    this.tosearch()
                }
            }


        },
        mounted() {
            this.modifyLayout()
        },
        created() {
            this.modifyLayout()
            this.getList()

            this.getitemType()
            setTimeout(() => {
                this.ItemTypeList = this.ItemType
            }, 1000)

            this.getdep()
            setTimeout(() => {
                this.depList = this.DepList
            }, 1000)

            this.getlevel()
            setTimeout(() => {
                this.levelList = this.LevelList
            }, 1000)
        },

        methods: {


            getList() {
                if (this.ISSenior == false) {
                    this.wasteInfo.rkstatus = ''
                }


                // var a = this.wasteInfo
                var a = {
                    ...this.wasteInfo
                }

                if (this.wasteInfo.sealing_time && this.wasteInfo.sealing_time.length > 1) {
                    a.sealing_time = this.wasteInfo.sealing_time.toString()
                }


                a.page_size = this.data.size
                a.page = this.data.page

                a.version_id = this.UserVersionId

                this.$http
                    .get('/api/number', a)
                    .then((res) => {
                        // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                        if (res.code != 200) {
                            //请求错误
                            this.$message.error(res.msg)
                        }

                        if (res.code == 200) {
                            // this.setMedicalWasteItem(res.data.results)
                            // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", this.MedicalWasteItem);

                            this.data.list = res.data.data
                            this.data.total = res.data.total

                            this.data.weight = res.data.weight

                            setTimeout(() => {
                                this.modifyLayout()
                            }, 1100)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },

            tosearch() {
                this.data.page = 1
                this.getList()
            },

            rowclick(row, column, event) {
                // console.log("!!!!!!!!!!!!!!!", row);
                // this.$refs.searchRef.open()
            },

            handleCurrentChange(val) {
                console.log(`当前页: ${val}`)

                this.data.page = val

                this.getList()
            },

            edit(row) {
                console.log(row)
                this.$refs.searchRef.edit(row)
            },

            view(row) {
                console.log(row)
            },

            del(row) {
                console.log(row)

                const h = this.$createElement
                this.$msgbox({
                    title: '删除',
                    message: h('p', null, [h('span', null, '该操作将删除所选数据，您确定要删除吗？删除后将无法恢复！')]),
                    showCancelButton: true,
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true
                            instance.confirmButtonText = '执行中...'

                            // setTimeout(() => {
                            //     done();
                            //     setTimeout(() => {
                            //         instance.confirmButtonLoading = false;
                            //     }, 300);
                            // }, 3000);

                            this.$http
                                .del('/api/number/' + row.id, {})
                                .then((res) => {
                                    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', res)
                                    if (res.code == 200) {
                                        this.getList()
                                    }

                                    // setTimeout(() => {
                                    done()
                                    setTimeout(() => {
                                        instance.confirmButtonLoading = false
                                    }, 300)
                                    // }, 3000);

                                    if (res.code != 200) {
                                        //请求错误
                                        this.$message.error(res.msg)
                                    }
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        } else {
                            done()
                        }
                    }
                }).then((action) => {
                    // this.$message({
                    //     type: 'info',
                    //     message: 'action: ' + action
                    // });
                })
            },

            toDel() {
                var row = 1
                console.log(row)

                const h = this.$createElement
                this.$msgbox({
                    title: '提示',
                    message: h('p', null, [
                        h('span', null, '您确定要删除所选信息吗？删除后可能会导致数据丢失且删除操作不能撤销！')
                    ]),
                    showCancelButton: true,
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true
                            instance.confirmButtonText = '执行中...'
                            setTimeout(() => {
                                done()
                                setTimeout(() => {
                                    instance.confirmButtonLoading = false
                                }, 300)
                            }, 3000)
                        } else {
                            done()
                        }
                    }
                }).then((action) => {
                    this.$message({
                        type: 'info',
                        message: 'action: ' + action
                    })
                })
            },


            handleClick(tab, event) {
                console.log(tab, event);
            },


            modifyLayout() {
                const erd = resizeDetector()
                erd.listenTo(document.getElementsByClassName('search'), (element) => {
                    const width = element.offsetWidth
                    const height = element.offsetHeight
                    // var a = 141 + 60 + 53 + height
                    var a = 141 + 60 + 0 + height
                    if ($('.level_indexII .FanfuTableBox')[0]) {
                        $('.level_indexII .FanfuTableBox')[0].setAttribute('style', 'height: calc(100vh - ' +
                            a + 'px) !important')
                    }

                    // var b = 141 + 60 + 70 + 53 + height
                    var b = 141 + 60 + 70 + 0 + height
                    if ($('.level_indexII .el-table')[0]) {
                        $('.level_indexII .el-table')[0].setAttribute('style', 'height: calc(100vh - ' + b +
                            'px) !important')
                    }
                })
            }
        }
    }
</script>
<style scoped>
    .el-button--mini.is-round {
        padding: 4px 6px;
    }

    .SelfSelect {
        /* width: 140px; */
        margin-right: 10px;

        /* border: 1px red dotted;
        box-sizing: border-box; */
    }

    .SelfSelect:nth-of-type(2) {
        /* width: 160px; */
        /* margin-right: 10px; */

        /* border: 1px red dotted;
        box-sizing: border-box; */
    }

    .SelfSelect:last-of-type {
        width: 150px;
        /* margin-right: 0px; */

        /* border: 1px red dotted;
        box-sizing: border-box; */

    }

    .OutputBox {
        margin: -5px 0 10px 0;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #6a83fc;
        line-height: 20px;
        /* height: 30px; */
    }

    .OutputBox small {
        margin: 0 10px;
    }

    .operationBtn {
        /* border: 1px red dotted !important;
    box-sizing: border-box; */
        padding: 5px 10px;
    }

    .operationBtn.el-button--primary {
        color: #ffffff;
        /*     background-color: #5bd995 !important;
        border-color: #5bd995 !important; */
    }

    .operationBtn.el-button--danger {
        color: #ffffff;
        /*        background-color: #ff754c !important;
        border-color: #ff754c !important; */
    }




    /* .el-tableII { */
    /* 		border: 1px red dotted !important;
		box-sizing: border-box !important; */
    /*    height: calc(100% - 100px) !important; */
    /* height: calc((100vh - 183px) - 100px); */
    /* height: auto; */
    /* } */

    /* 
    .el-table th.el-table__cell {
        background-color: #f5f5f5 !important;
    } */
</style>